import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'
import Seo from '../components/seo'

import MiniLawnChair from '../images/miniLawnChair.svg'

const IndexPage = () => (
	<Layout>
		<Seo title="Home"
			canonical="https://gardenescape.ca" />

		<div className="serviceGrid">
			<StaticImage
				className="serviceGrid_boxA"
				src="../images/home/lawnChairs.png"
				alt="background placeholder"
				layout="fullWidth" />
			<div className="serviceGrid_boxB">
				<h1 className="">Professional Landscaping Services</h1>
				<p className="serviceGrid_boxB_text">Escape the Yardwork</p>
				<img
					src={MiniLawnChair}
					width="75px"
					height="75px"
					alt="garden escape mini logo" />
			</div>
		</div>

		<div aria-labelledby="about" className="mainWrapper my-16">
			<h2 id="about">WE DO THE YARDWORK SO YOU DON'T HAVE TO</h2>
			<p>
				We take care of all the yard work and landscaping chores so you and your neighbours can enjoy their
				garden escape unfettered.
			</p>
			<p>
				At Garden Escape, our policy is ‘over-deliver, under-promise’. We want every client to know that they
				are important to us. No matter the size of the job, every project will be completed with great care and
				attention.
			</p>
			<p>
				We believe you should be able to fully enjoy your property without the stress and burden of the
				maintenance that comes along with it. We have the right tools and know-how to keep your property looking
				amazing for all season long.
			</p>
		</div>

		<div className="bg-brand-2 py-8">
			<div aria-labelledby="services-title" className="mainWrapper my-8">
				<div className="my-0 mx-auto flex flex-col">
					<h2 id="services-title" className="pb-0 mb-2">
						OUR FULL LIST OF SERVICES
					</h2>
					<p className=" mt-0 pt-0 text-center">
						We offer a wide range of services to suit your specific needs
					</p>

					<div className="homeServicesList">
						<ul>
							<li>
								<Link to="/services/lawn-mowing/" title="lawn mowing services">✓ Lawn Mowing</Link>
							</li>
							<li>
								<Link to="/services/lawn-fertilizing/" title="lawn fertilizing services">✓ Lawn
									Fertilizing</Link>
							</li>
							<li>
								<Link to="/services/yard-cleanup/" title="yard cleanup services">✓ Seasonal Yard
									Cleanup</Link>
							</li>
							<li>
								<Link to="/services/shrub-pruning/" title="hedge and shrub pruning services">✓ Hedge &
									Shrub Pruning</Link>
							</li>
						</ul>
						<ul>
							<li>
								<Link to="/services/lawn-aeration/" title="Lawn Aeration services">✓ Lawn
									Aeration</Link>
							</li>
							<li>
								<Link to="/services/power-washing/" title="Power Raking services">✓ Power Raking</Link>
							</li>
							<li>
								<Link to="/services/power-washing/" title="Power Washing services">✓ Power
									Washing</Link>
							</li>
							<li>
								<Link to="/services/junk-removal/" title="Junk Removal services">✓ Junk Removal</Link>
							</li>
							<li>
								<Link to="/services/hardscaping/" title="hardscape and hardscaping services">✓
									Hardscaping</Link>
							</li>
						</ul>
					</div>
					<div className="mx-auto mt-8">
						<Link to="/contact/" title="call to get 25% off your first mow">
							<button>
								I want 25% off my first mow!
							</button>
						</Link>
					</div>
				</div>
			</div>
		</div>

		<div className="homeAltGridWrapper m-0 p-0">
			<div>
				<div className="homeAltGrid">
					<StaticImage
						className="homeAltGrid_img"
						src="../images/home/grass.png"
						alt="fresh cut grass"
						layout="fullWidth" />
					<div className="homeAltGrid_boxA">
						<p className="homeAltGrid_boxA_text">LAWN MAINTENANCE</p>
					</div>
				</div>
			</div>
			<div>
				<div className="homeAltGrid">
					<StaticImage
						className="homeAltGrid_img"
						src="../images/home/leaves.png"
						alt="raked leaves"
						layout="fullWidth" />
					<div className="homeAltGrid_boxA">
						<p className="homeAltGrid_boxA_text">SEASONAL CLEANUP</p>
					</div>
				</div>
			</div>
			<div>
				<div className="homeAltGrid">
					<StaticImage
						className="homeAltGrid_img"
						src="../images/home/bush.png"
						alt="trimmed and pruned hedges"
						layout="fullWidth" />
					<div className="homeAltGrid_boxA">
						<p className="homeAltGrid_boxA_text">TRIMMING PRUNING</p>
					</div>
				</div>
			</div>
		</div>
	</Layout>
)

export default IndexPage
